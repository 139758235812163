import React, { useEffect, useImperativeHandle, useRef, forwardRef } from "react";

import CustomModal from "./CustomModal";
import { Box, Button } from "@mui/material"; 
import { useDispatch, useSelector } from "react-redux";
import { AddCircleOutlineIcon, CloseIcon, CustomModalTypes, Grid, InputLabel, MenuItem, TextField, Typography, validateArrayFields } from "@enerbit/base";
import { AppDispatch } from "../../../../../store/store";
import { editClientArray, errorsArray, validateArrayFieldsState } from "../../../../../features/serviceUser/serviceUserSlice";
import { PropsModalMail } from "../../../../../models/serviceUserState"; 
import { ObjectFormikValues } from "@enerbit/base/common/models/validateArray";

const ModalArrayForm = forwardRef(({formik, type}: PropsModalMail, ref) => { 
  const createClient = useRef<CustomModalTypes>(null);
  const editData = useSelector(editClientArray);
  const errors = useSelector(errorsArray); 
  const dispatch = useDispatch<AppDispatch>();
  const singularFiled = type.replace("s", ""); 
  
  useImperativeHandle(ref, () => ({
    changeModal() { 
      if(createClient.current)
      {
        createClient.current.changeModal(); 
      }
    },
  }));

  const deletePosition = (index: number) => 
  {
    formik.values[type].splice(index, 1);
    formik.setFieldValue(type, formik.values[type]);
  }

  const addPositions = () => 
  {
    formik.setFieldValue(type, [...formik.values[type], {
      name: `${type}${formik.values[type].length}`,
      priority: "",
      [singularFiled]: "",
      id: ""
    }]); 
  }
  const setValuesToFormArray = (object: ObjectFormikValues[], field: string) => { 

    let formatValue = object.map((value, index) => {
      return {
        name: `${field}${index}`,
        [singularFiled]: `${value[singularFiled as keyof typeof value]}`,
        priority: value.priority,
        id: value.id,
      }
    }).sort((a,b) => a.priority! < b.priority! ? -1 : 1);  

    formik.setFieldValue(field, formatValue); 
  }
  useEffect(() => {
    if( editData ){     
      setValuesToFormArray(editData[type], type);
    }
  }, [editData])
  
  return (
      <CustomModal
        maxWidth="xl" 
        ref={createClient}
        onClose={() => { }}
        dialogContent={
          <>
            <Box 
              sx={{ fontWeight: "400", mb: "0.5rem" }}
              className="Modal-title-information"
            >
              { type == "emails" ? "CORREO ELECTRONICO" : "TELÉFONO"}
            </Box> 
            {
              formik.values[type] && formik.values[type].map((value: any, index: number) => {
                return (
                  <Box sx={{ display: "flex" }} key={index}>
                    <Grid item xs={12} md={12} sx={{ mb: "15px" }}>
                      <InputLabel shrink className="Input-label">
                        {type == "emails" ? "Correo" : "Teléfono"}
                      </InputLabel>
                      <Box className="TextField-without-border-radius Text-field-filters">
                        <TextField
                          placeholder={`Escribir ${type === "emails" ? "Correo" : "Teléfono"}`}
                          name={`${type}${index}`}
                          value={`${formik.values[type][index][singularFiled]}`}
                          sx={{ width: "20rem", borderRadius: "14px" }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            const values = [...formik.values[type]]
                            values[index][singularFiled] = e.target.value
                            formik.setFieldValue(type, values);
                          }}
                        error={ Boolean( errors[type][type+"_"+index] ) }
                        helperText={ errors[type][type+"_"+index] }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mb: "15px", pl: "10px" }}>
                      <InputLabel shrink className="Input-label">
                        Prioridad
                      </InputLabel>
                      <Box className="TextField-without-border-radius Text-field-filters">
                        <TextField
                          name={`${type}${index}`}
                          sx={{ width: "6.5rem", borderRadius: "14px" }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            const values = [...formik.values[type]]
                            values[index].priority = e.target.value
                            formik.setFieldValue(type, values);
                          }}
                          value={`${formik.values[type][index].priority}`}
                          error={ Boolean(errors[type][type+"_priority_" + index]) }
                          helperText={ errors[type][type+"_priority_" + index] || "" }
                          select
                        >
                          <MenuItem value={""}></MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </TextField>
                      </Box>
                    </Grid>
                    {index != 0 && 
                    <Grid item xs={2} md={2}>
                      <Button
                        variant="contained"
                        onClick={() => deletePosition(Number(index))}
                        className="Button-delete-modal-bitoria"
                      >
                        <CloseIcon sx={{ color: "var(--color-error500)" }} />
                      </Button>
                    </Grid>}
                  </Box>
                )
              })
            } 
            <Button
              variant="outlined"
              color="primary"
              className="Button-add-modal-bitoria"
              disabled={formik.values[type].length == 3 }
              onClick={addPositions}
            >
              <Box style={{ fontWeight: "bold", margin: 0 }}>
                <Box sx={{ display: "inline-flex", mt: "4px" }}>
                  <Typography sx={{ mr: "10px", fontWeight: "700" }}>
                    <AddCircleOutlineIcon />
                  </Typography>
                  <Typography sx={{ mr: "10px", mt: "3px", fontWeight: "700 !important" }}>
                    Agregar nuevo { type == "emails" ? "correo" : "teléfono"}
                  </Typography>
                </Box>
              </Box>
            </Button>
          </>
        } 
        dialogActions={
          <>
            <Box className="Modal-button-container">
              <Button
                color="warning"
                size="small"
                className="Modal-button-logout-cancel"
                onClick={() => {
                  if(createClient.current)
                  { 
                    createClient.current.changeModal(); 
                  } 
                }}
                sx={{ width: "48%", height: "3rem" }}
              >
                <Box style={{ fontWeight: "bold", margin: 0 }}>Volver</Box>
              </Button>

              <Button
                color="warning"
                size="small"
                className="Loading-button Modal-button-logout"
                onClick={() => {
                  const errors = validateArrayFields(formik.values);
                  dispatch(validateArrayFieldsState(errors));
                  if (Object.keys(errors[type]).length === 0) {
                    if(createClient.current)
                    {
                      createClient.current.changeModal(); 
                    } 
                  }
                }}
                sx={{ width: "48%", height: "3rem !important" }}
              >
                <Box
                  style={{ fontWeight: "bold", margin: 0 }}>Guardar</Box>
              </Button>
            </Box> 
          </>
        }  
      />
  );
});

export default ModalArrayForm;