import * as singleSpa from "single-spa";

export type InvoicesMicroProps = {
  document_group_id?: string;
  service_account_id?: string;
  user_id: string;
  is_paid?: boolean;
};

export const reloadServicesAccount = (
  service: string,
  tab: number,
  shouldShowNewUser: boolean,
  paramsinvoices: InvoicesMicroProps
) => {
  if (tab === 0) {
    mountedDisassembleAccessManagement(service, shouldShowNewUser);
  } else if (tab === 2) {
    mountedDisassembleInvoices(paramsinvoices);
  }
};

const mountedAppSingleSpaAccessManagement = (
  idServiceAccount: string,
  shouldShowNewUser: boolean
) => {
  singleSpa.registerApplication(
    "app-access-management",
    (): Promise<any> =>
      System.import("@enerBit/access-management").catch((e) =>
        console.log("error")
      ),
    (location) => location.pathname.startsWith("/"),
    {
      idServiceAccount: idServiceAccount,
      shouldShowNewUser: shouldShowNewUser,
    }
  );
};

const mountedAppSingleSpaInvoices = (params: InvoicesMicroProps) => {
  singleSpa.registerApplication(
    "app-invoices",
    (): Promise<any> =>
      System.import("@enerBit/invoices").catch((e) => console.log("error")),
    (location) => location.pathname.startsWith("/"),
    params
  );
};

const mountedAppSingleSpaPayments = (paymerId: string) => {
  singleSpa.registerApplication(
    "app-payments",
    (): Promise<any> =>
      System.import("@enerBit/payments").catch((error) => console.log("error")),
    (location) => location.pathname.startsWith("/"),
    {
      paymerId: paymerId,
    }
  );
};

const mountedAppSingleSpaNotifications = (userId: string) => {
  singleSpa.registerApplication(
    "app-notifications",
    (): Promise<any> =>
      System.import("@enerBit/notifications").catch((e) =>
        console.log("error")
      ),
    (location) => location.pathname.startsWith("/"),
    {
      userId: userId,
    }
  );
};

export const mountedDisassembleInvoices = async (
  params: InvoicesMicroProps
) => {
  const appNamesInvoices = singleSpa
    .getAppNames()
    .find((item) => item == "app-invoices");

  if (appNamesInvoices) {
    singleSpa
      .unregisterApplication("app-invoices")
      .then(() => mountedAppSingleSpaInvoices(params));
  } else {
    mountedAppSingleSpaInvoices(params);
  }
};

export const mountedDisassembleAccessManagement = async (
  idServiceAccount: string,
  shouldShowNewUser: boolean
) => {
  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-access-management");

  if (appNamesAccess) {
    singleSpa
      .unregisterApplication("app-access-management")
      .then(() =>
        mountedAppSingleSpaAccessManagement(idServiceAccount, shouldShowNewUser)
      );
  } else {
    mountedAppSingleSpaAccessManagement(idServiceAccount, shouldShowNewUser);
  }
};

export const mountedDisassembleAppPayments = async (id: string) => {
  const appNamesPayments = singleSpa
    .getAppNames()
    .find((item) => item == "app-payments");

  if (appNamesPayments) {
    singleSpa
      .unregisterApplication("app-payments")
      .then(() => mountedAppSingleSpaPayments(id));
  } else {
    mountedAppSingleSpaPayments(id);
  }
};

export const mountedDisassembleAppNotifications = async (id: string) => {
  const appNamesNotifications = singleSpa
    .getAppNames()
    .find((item) => item == "app-notifications");

  if (appNamesNotifications) {
    singleSpa
      .unregisterApplication("app-notifications")
      .then(() => mountedAppSingleSpaNotifications(id));
  } else {
    mountedAppSingleSpaNotifications(id);
  }
};
