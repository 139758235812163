import * as singleSpa from "single-spa";

export type InvoicesMicroProps = {
	document_group_id?: string;
	service_account_id?: string;
	user_id: string;
	is_paid?: boolean;
};

export const unmountMicrofrontends = (
) => {
	const apps = ['app-invoices', 'app-pqrs', 'app-payments', 'app-notifications', 'app-access-management']

	apps.forEach((app) => {
		let status = singleSpa.getAppStatus(app);
		if (status == "NOT_LOADED" || status == "MOUNTED") {
			singleSpa.unregisterApplication(app);
		}
	})
};

export const reloadServicesAccount = (
	service: string,
	tab: number,
	shouldShowNewUser: boolean,
	paramsinvoices: InvoicesMicroProps,
) => {
	if (tab === 0) {
		mountedDisassembleAccessManagement(service, shouldShowNewUser);
	} else if (tab === 2) {
		mountedDisassembleInvoices(paramsinvoices);
	}
};

const mountedAppSingleSpaInvoices = (params: InvoicesMicroProps) => {
	singleSpa.registerApplication(
		"app-invoices",
		(): Promise<any> =>
			System.import("@enerBit/invoices").catch((e) => console.log("error")),
		(location) => location.pathname.startsWith("/"),
		params,
	);
};

const mountedAppSingleSpaPqrs = (email: string) => {
	singleSpa.registerApplication(
		"app-pqrs",
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		(): Promise<any> => {
			const aux = System.import("@enerBit/pqrs").catch((e) => {
				console.log("error", e);
				return {} as System.Module;
			});
			return aux;
		},
		(location) => location.pathname.startsWith("/"),
		{
			email: email,
		},
	);
};

const mountedAppSingleSpaPayments = (paymerId: string) => {
	singleSpa.registerApplication(
		"app-payments",
		(): Promise<any> =>
			System.import("@enerBit/payments").catch((error) => console.log("error")),
		(location) => location.pathname.startsWith("/"),
		{
			paymerId: paymerId,
		},
	);
};

const mountedAppSingleSpaNotifications = (userId: string) => {
	singleSpa.registerApplication(
		"app-notifications",
		(): Promise<any> =>
			System.import("@enerBit/notifications").catch((e) =>
				console.log("error"),
			),
		(location) => location.pathname.startsWith("/"),
		{
			userId: userId,
		},
	);
};

export const mountedDisassemblePqrs = async (email: string) => {
	const appNamesPqrs = singleSpa
		.getAppNames()
		.find((item) => item == "app-pqrs");

	if (appNamesPqrs) {
		singleSpa
			.unregisterApplication("app-pqrs")
			.then(() => mountedAppSingleSpaPqrs(email));
	} else {
		mountedAppSingleSpaPqrs(email);
	}
};

export const mountedDisassembleInvoices = async (
	params: InvoicesMicroProps,
) => {
	const appNamesInvoices = singleSpa
		.getAppNames()
		.find((item) => item == "app-invoices");

	if (appNamesInvoices) {
		singleSpa
			.unregisterApplication("app-invoices")
			.then(() => mountedAppSingleSpaInvoices(params));
	} else {
		mountedAppSingleSpaInvoices(params);
	}
};

export const mountedDisassembleAccessManagement = async (
  idServiceAccount: string,
  shouldShowNewUser: boolean
) => {
  let status = singleSpa.getAppStatus("app-access-management");

  if (status == "NOT_LOADED" || status == "MOUNTED") {
    await singleSpa.unregisterApplication("app-access-management");
    status = singleSpa.getAppStatus("app-access-management");
  }

  if (status == null || status == "UNLOADED") {
    singleSpa.registerApplication(
      "app-access-management",
      (): Promise<any> =>
        System.import("@enerBit/access-management").catch((e) =>
          console.log("error")
        ),
      (location) => location.pathname.startsWith("/"),
      {
        idServiceAccount: idServiceAccount,
        shouldShowNewUser: shouldShowNewUser,
      }
    );
  }
};

export const mountedDisassembleAppPayments = async (id: string) => {
	const appNamesPayments = singleSpa
		.getAppNames()
		.find((item) => item == "app-payments");

	if (appNamesPayments) {
		singleSpa
			.unregisterApplication("app-payments")
			.then(() => mountedAppSingleSpaPayments(id));
	} else {
		mountedAppSingleSpaPayments(id);
	}
};

export const mountedDisassembleAppNotifications = async (id: string) => {
	const appNamesNotifications = singleSpa
		.getAppNames()
		.find((item) => item == "app-notifications");

	if (appNamesNotifications) {
		singleSpa
			.unregisterApplication("app-notifications")
			.then(() => mountedAppSingleSpaNotifications(id));
	} else {
		mountedAppSingleSpaNotifications(id);
	}
};
