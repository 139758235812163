import { configureStore } from "@reduxjs/toolkit";
import serviceAccountReducer from "../features/serviceAccount/serviceAccountSlice"; 
import serviceUserReducer from "../features/serviceUser/serviceUserSlice"; 

export const store = configureStore({
  reducer: {
    serviceAccount: serviceAccountReducer, 
    serviceUser: serviceUserReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
