import { store } from "../store/store";
import { Provider } from "react-redux";
import { ThemeConfig, PrivateRoute, Box } from "@enerbit/base";
import ServiceAccountMain from "../pages/serviceAccount/ServiceAccountMain";
import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

export default function Routers () {
  return <React.StrictMode>
    <HashRouter basename="/services-account">
    <Routes>
      <Route path="/" element={
        <Provider store={store}>
        <ThemeConfig>
          <PrivateRoute>
            <>
              <section className="Container-root">
                <ServiceAccountMain/>
              </section>
            </>
          </PrivateRoute>
        </ThemeConfig>
      </Provider>
      } />
      <Route path="history-payments" element={
        <Provider store={store}>
        <ThemeConfig>
          <PrivateRoute>
            <Box>Prueba</Box>
          </PrivateRoute>
        </ThemeConfig>
      </Provider>
      } />
    </Routes>
  </HashRouter>
  </React.StrictMode>
}