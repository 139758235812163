import {
  BoltIcon,
  Box,
  CircularProgress,
  Grid,
  ServiceCard,
  ServiceTypeNames,
  WbSunnyIcon,
  enerbitColors,
  serviceNames,
  thirdPartyServiceNames,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { navigateToUrl } from "single-spa";
import { StateStorage } from "src/models/stateStorage";
import {
  isLoadingCards,
  servicesAccountCards,
} from "../../../../features/serviceAccount/serviceAccountSlice";

const defaultIcon = <BoltIcon sx={{ fontSize: "31px", color: "white" }} />

const ServicesCards = () => {
  const cards = useSelector(servicesAccountCards);
  const loading = useSelector(isLoadingCards);
  const { searchUser } = useSelector(
    (state: StateStorage) => state.serviceUser
  );
 
  const getServiceCard = (serviceType: string, ess_id: string, solar_id?: string) => {
    const servicesCard = {
      [ServiceTypeNames.electricitySupplyServices]: {
        onClick: () => navigateToUrl(
          `/#/electricity-supply-service/attention/${ess_id}?userId=${searchUser?.id ?? ""}`
        ),
        color: enerbitColors.secondary.orange.main,
        icon: defaultIcon,
      },
      [ServiceTypeNames.solarMonitoring]: {
        onClick: () => navigateToUrl(
          `/#/solar-monitoring/attention/${ess_id}/${solar_id}?userId=${searchUser?.id ?? ""}`
        ),
        color: "#E99D0D",
        icon: <WbSunnyIcon sx={{ fontSize: "31px", color: "white" }} />,
      }
    }
    return servicesCard[serviceType as keyof typeof servicesCard];
  }

  return (
    <Box className="Services-card-main-accounts">
      {loading ? (
        <Box className="Loading">
          <CircularProgress />
        </Box>
      ) : (
        <Grid className="Grid-service-cards" container>
          {cards &&
            cards.map((value, index: number) => {
              const essIdBySolar = value.service_type_name === ServiceTypeNames.solarMonitoring ? cards.find(card => card.service_type_name === ServiceTypeNames.electricitySupplyServices)?.service_id : undefined
              const serviceCard = getServiceCard(value.service_type_name, value.service_id, essIdBySolar)
              return <ServiceCard
                onClick={() => serviceCard?.onClick()}
                sx={{
                  cursor: serviceCard ? "pointer" : "not-allowed !important",
                  minHeight: "120px !important",
                }}
                key={index}
                background={serviceCard?.color ?? enerbitColors.primary.main}
                icon={ serviceCard?.icon ??  defaultIcon}
                container={
                  <>
                    <Box className="Service-card-title">
                      {
                        value?.service_type_name
                        ? thirdPartyServiceNames[value.service_type_name] || serviceNames[value.service_type_name]
                        : null
                      }
                    </Box>
                    <Box>{value.estate_address}</Box>
                    <Box>{value.estate_personalization}</Box>
                  </>
                }
              />
            })}
        </Grid>
      )}
    </Box>
  );
};

export default ServicesCards;
