import { ServiceTypeNames } from "@enerbit/base";
import { ThirdPartyServiceTypeNames } from "@enerbit/base/common/models/ThirdPartyServiceTypeNames";

export enum thirdPartyServices {
  Security = "billerbit-security",
  StreetLight = "billerbit-street-light",
  InfrastructureFee = "distribution-infrastructure-fee",
}

export const isThirdPartyService = (serviceTypeName: string): boolean => {
  return Object.values(ThirdPartyServiceTypeNames).includes(
    serviceTypeName as ThirdPartyServiceTypeNames
  );
};

export const isMainService = (serviceTypeName: string): boolean => {
  return [ServiceTypeNames.electricitySupplyServices, ServiceTypeNames.solarMonitoring].includes(
    serviceTypeName as ServiceTypeNames
  );
}