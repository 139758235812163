import {
  Box,
  Button,
  CustomAlert,
  CustomModal,
  CustomModalTypes,
  InputLabel,
  LoadingButton,
  TextField,
  enerbitColors,
  theme,
  useFormik,
  yup,
} from "@enerbit/base";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store/store";
import {
  isLoadingUpdateUsername,
  setResetVars,
  setShowConfirmDialog,
  showConfirmDialog,
  updateUsername,
  wasUpdateUsername,
} from "../../../../../features/serviceUser/serviceUserSlice";

const EditUsername = forwardRef((props, ref) => {
  const editUsername = useRef<CustomModalTypes>(null);

  const loading = useSelector(isLoadingUpdateUsername);
  const isUpdateUsername = useSelector(wasUpdateUsername);
  const confirmDialog = useSelector(showConfirmDialog);
  const dispatch = useDispatch<AppDispatch>();

  useImperativeHandle(ref, () => ({
    changeModal() {
      if (editUsername.current) {
        editUsername.current.changeModal();
      }
    },
  }));

  const initialValues = {
    username: "",
  };

  const validationSchema = yup.object({
    username: yup
      .string()
      .required("Usuario es requerido")
      .matches(
        /^[a-z0-9][-a-z0-9._]+@([-a-zA-Z0-9]+\.)+[a-z]{2,5}$/gm,
        "Correo incorrecto"
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => dispatch(updateUsername(values.username)),
  });

  useEffect(() => {
    if (isUpdateUsername) {
      dispatch(setResetVars());
      formik.resetForm();
      editUsername.current?.changeModal();
    }
  }, [isUpdateUsername]);

  return (
    <CustomModal
      ref={editUsername}
      onClose={() => {
        dispatch(setResetVars());
        formik.resetForm();
      }}
      dialogContent={
        <form onSubmit={formik.handleSubmit}>
          <>
            {confirmDialog ? (
              <Box
                sx={{
                  fontSize: "24px",
                  color: enerbitColors.primary.main,
                  textAlign: "center",
                }}
              >
                ¿Está seguro que quiere cambiar el nombre de usuario a{" "}
                {formik.values.username}?
              </Box>
            ) : (
              <>
                <Box
                  className="Dialog-close-box"
                  color={theme.palette.primary.main}
                >
                  Cambiar nombre de usuario
                </Box>
                <Box className="TextField-without-border-radius TextField-width-login">
                  <InputLabel shrink className="Input-label">
                    Nuevo nombre de usuario
                  </InputLabel>
                  <TextField
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.username)}
                    helperText={formik.errors.username}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: "100%", borderRadius: "14px" }}
                  />
                </Box>
              </>
            )}
          </>

          {isUpdateUsername === false && (
            <CustomAlert
              onClose={() => {
                dispatch(setResetVars());
              }}
              sx={{ marginTop: "20px", backgroundColor: "#d1fadf !important" }}
              severity={isUpdateUsername ? "success" : "error"}
              text={
                isUpdateUsername
                  ? "El nombre de usuario fue actualizado con con éxito"
                  : "No se pudo actualizar el nombre de usuario, por favor intente nuevamente"
              }
            />
          )}
          <Box className="Modal-button-container" sx={{ padding: "0px" }}>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              className="Modal-button-logout-cancel"
              onClick={() => {
                if (editUsername.current) {
                  editUsername.current.changeModal();
                  formik.resetForm();
                  dispatch(setResetVars());
                }
              }}
              sx={{ width: "40%" }}
            >
              <Box
                style={{
                  fontWeight: "bold",
                  color: enerbitColors.secondary.orange.main,
                }}
              >
                Cancelar
              </Box>
            </Button>

            <LoadingButton
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                if (confirmDialog) {
                  formik.handleSubmit();
                } else {
                  formik.validateForm().then((e) => {
                    if (Object.keys(e).length === 0) {
                      dispatch(setShowConfirmDialog(true));
                    }
                  });
                }
              }}
              sx={{ width: "40%" }}
              loading={loading}
            >
              Guardar
            </LoadingButton>
          </Box>
        </form>
      }
    />
  );
});

export default EditUsername;
