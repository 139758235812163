import { Box, Tabs, Tab } from "@enerbit/base";
import { Fragment } from "react";
import { PropsTabs } from "src/models/serviceAccountState";

const TabsComponent = ({tabs, value, handleChange}: PropsTabs) => { 
  return (
    <Fragment>
      <Box className="Box-tabs-main"> 
        <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
            style: { backgroundColor: "orange", color: "black" },
        }}
        sx={{background: "white"}}
        >
        {tabs.map(({ label }, i) => (
          <Tab className="Custom-tabs" label={label} key={i} />
        ))}
        </Tabs>
      </Box>
      {tabs.map((values, indexS) => {
        return <Box
          sx={{background: "white"}}
          key={indexS}
          role="tabpanel"
          style={{display: Number(value) != indexS ? "none": "block"}} 
          id={`simple-tabpanel-${indexS}`}
          aria-labelledby={`simple-tab-${indexS}`} 
          > 
            <Box>
              {values.component}
            </Box>   
          </Box>
      })}  
    </Fragment> 
  )
}
  export default TabsComponent;