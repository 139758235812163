import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import React, { forwardRef, useImperativeHandle, useState } from "react";
import { HighlightOffIcon } from "@enerbit/base";
import {
  CustomModalProps,
  ImperativeHandle,
} from "../../../../../models/customPropsComponents";

const CustomModal = forwardRef<ImperativeHandle, CustomModalProps>(
  (props, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      changeModal() {
        setOpen(open ? false : true);
      },
    }));

    return (
      <Dialog className="Modal-container-display"
        PaperProps={{
          style: { borderRadius: "14px", width: "100%" },
        }}
        fullWidth
        open={open}
        id={props.id ?? "modal"}
      >
        <Box sx={{ padding: "0px 20px 20px 20px" }}>
          <DialogTitle sx={{ paddingBottom: "0px" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => {
                setOpen(false);
                props.onClose != undefined ? props.onClose() : "";
              }}
            >
              <HighlightOffIcon sx={{ fontSize: "35px", color: "#8876af" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="modal-dialog-content"
            sx={{ width: "100%", paddingBottom: "0px" }}
          >
            {props.dialogContent}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.dialogActions}
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
);

export default CustomModal;
