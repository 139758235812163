import {
  AdapterDayjs,
  Box,
  CustomAlert,
  CustomModalTypes,
  dayjs,
  DesktopDatePicker,
  EditIcon,
  getPriority,
  Grid,
  InputLabel,
  LoadingButton,
  LocalizationProvider,
  MenuItem,
  TextField,
  useFormik,
  yup,
} from "@enerbit/base";
import { DataUpdateSearchUser } from "@enerbit/base/common/models/validateArray";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  errorsArray,
  errorSendMessage,
  hasErrorUserEdit,
  isLoadingReset,
  isLoadingUserEdit,
  resetPassword,
  searchUser,
  setEditClientArray,
  setResetVars,
  successSendMessage,
  successUserEdit,
  updateSearchUser,
} from "../../../../features/serviceUser/serviceUserSlice";
import { AppDispatch } from "../../../../store/store";
import ModalArrayForm from "./modal/ModalArrayForm";

const UserInformationForm = () => {
  const newModalAddMail = useRef<CustomModalTypes>(null);
  const newModalAddPhones = useRef<CustomModalTypes>(null);

  const openModalAddMail = () => {
    if (newModalAddMail.current) {
      newModalAddMail.current.changeModal();
    }
  };

  const openModalAddPhone = () => {
    if (newModalAddPhones.current) {
      newModalAddPhones.current.changeModal();
    }
  };

  const dataUser = useSelector(searchUser);
  const isLoadingUser = useSelector(isLoadingUserEdit);
  const isLoadingSend = useSelector(isLoadingReset);
  const successUser = useSelector(successUserEdit);
  const hasErrorUser = useSelector(hasErrorUserEdit);
  const hasErrorUserSend = useSelector(errorSendMessage);
  const successUserSend = useSelector(successSendMessage);
  const errors = useSelector(errorsArray);

  const dispatch = useDispatch<AppDispatch>();

  const initialValues = {
    loggable: false,
    names: "",
    created_at: "",
    last_names: "",
    legal_id_type: "",
    is_first_time: false,
    legal_id_code: "",
    verification_code: null,
    app_user_id: "",
    emails: [
      {
        name: "emails0",
        id: "",
        email: "",
        priority: "",
      },
      {
        name: "emails1",
        id: "",
        value: "",
        priority: "",
      },
    ],
    phones: [
      {
        name: "phones0",
        id: "",
        phone: "",
        priority: "",
      },
      {
        name: "phones1",
        id: "",
        phone: "",
        priority: "",
      },
    ],
  };

  const validationSchema = yup.object({
    loggable: yup.string().required("Ha iniciado sesión es requerido"),
    names: yup.string().required("Nombre es requerido"),
    created_at: yup.string().required("Fecha creación es requerido"),
    legal_id_type: yup.string().required("Tipo documento es requerido"),
    is_first_time: yup.string().required("Ha iniciado sesion es requerido"),
    legal_id_code: yup.string().required("Documento es requerido"),
    verification_code: yup.number().nullable().test(`validate-verification-code`, "", function (value) {
      const { path, createError } = this;
      if (formik.values.legal_id_type == "NIT" && formik.values.verification_code == null) {
        return createError({ path, message: "El código de verificación es requerido" });
      }
      return true;
    }),
    last_names: yup.string().test(`validate-lastnames`, "", function (value) {
      const { path, createError } = this;
      if (formik.values.legal_id_type != "NIT" && formik.values.last_names == "") {
        return createError({ path, message: "El apellido es requerido" });
      }
      return true;
    }),
  });

  let formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: DataUpdateSearchUser) => {
      if (values.legal_id_type == "NIT") {
        values.last_names = "";
      } else {
        values.verification_code = null;
      }

      if (Object.keys(errors.emails).length !== 0) {
        openModalAddMail();
      }
      if (Object.keys(errors.phones).length !== 0) {
        openModalAddPhone();
      }
      if (Object.keys(errors.emails).length === 0 && Object.keys(errors.phones).length === 0) {
        dispatch(updateSearchUser(values));
      }
    },
  });

  useEffect(() => {
    if (dataUser) {
      formik.setFieldValue("names", dataUser.pii.names);
      formik.setFieldValue("created_at", dataUser.created_at);
      formik.setFieldValue("loggable", dataUser.loggable.toString());
      formik.setFieldValue("is_first_time", dataUser.is_first_time.toString());
      formik.setFieldValue("legal_id_type", dataUser.pii.legal_id_type);
      formik.setFieldValue("verification_code", dataUser.pii.verification_code);
      formik.setFieldValue("last_names", dataUser.pii.last_names);
      formik.setFieldValue("phones", dataUser.pii.phones[0]?.phone);
      formik.setFieldValue("app_user_id", dataUser.pii.app_user_id);
      formik.setFieldValue("legal_id_code", dataUser.pii.legal_id_code);
      formik.setFieldValue("email", dataUser.pii.emails[0]?.email);

      dispatch(
        setEditClientArray({
          phones: dataUser?.pii?.phones,
          emails: dataUser?.pii?.emails,
        })
      );
    }
  }, [dataUser]);

  return (
    <form onSubmit={formik.handleSubmit} style={{ padding: "35px" }}>
      <Box>
        <Box className="Text-header-title">Información básica</Box>
        <Box className="TextField-without-border-radius">
          <InputLabel shrink className="Input-label">
            Correo electrónico
          </InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                name="email"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                sx={{ width: "100%", borderRadius: "14px" }}
                value={getPriority(formik.values.emails)?.email}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={5} display="center">
              <LoadingButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={openModalAddMail}
                className="Loading-button"
                sx={{ width: "100%" }}
                loading={isLoadingSend}
              >
                <Box style={{ fontWeight: "bold", margin: 0, color: "#53358E" }}>Mostrar correos</Box>
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <Box className="TextField-without-border-radius" sx={{ width: "100%" }}>
          <InputLabel shrink className="Input-label">
            Fecha creación
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              onChange={() => {}}
              value={dayjs(formik.values.created_at)}
              disabled
              slotProps={{ textField: { variant: "outlined" } }}
            />
          </LocalizationProvider>
        </Box>
        <Box className="TextField-without-border-radius">
          <InputLabel shrink className="Input-label">
            ¿Puede iniciar sesión?
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="loggable"
            disabled
            select
            sx={{ width: "100%" }}
            value={formik.values.loggable}
            onChange={formik.handleChange}
            error={formik.touched.loggable && Boolean(formik.errors.loggable)}
            helperText={formik.touched.loggable && formik.errors.loggable}
          >
            <MenuItem value={"true"}>SI</MenuItem>
            <MenuItem value={"false"}>NO</MenuItem>
          </TextField>
        </Box>
        <Box className="TextField-without-border-radius">
          <InputLabel shrink className="Input-label">
            ¿Ha iniciado sesión?
          </InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                name="is_first_time"
                select
                disabled
                sx={{ width: "100%" }}
                value={formik.values.is_first_time}
                onChange={formik.handleChange}
                error={formik.touched.is_first_time && Boolean(formik.errors.is_first_time)}
                helperText={formik.touched.is_first_time && formik.errors.is_first_time}
              >
                <MenuItem value={"false"}>SI</MenuItem>
                <MenuItem value={"true"}>NO</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} display="center">
              <LoadingButton
                variant="outlined"
                color="warning"
                size="small"
                onClick={() => {
                  dispatch(resetPassword());
                }}
                className="Loading-button"
                sx={{ width: "100%" }}
                loading={isLoadingSend}
              >
                <Box style={{ fontWeight: "bold", margin: 0, color: "#f79009" }}>Reiniciar contraseña</Box>
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <Box className="TextField-without-border-radius">
          <InputLabel shrink className="Input-label">
            {formik.values.legal_id_type != "NIT" ? "Nombres" : "Razón social"}
          </InputLabel>
          <TextField
            name="names"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "100%", borderRadius: "14px" }}
            value={formik.values.names}
            onChange={formik.handleChange}
            error={formik.touched.names && Boolean(formik.errors.names)}
            helperText={formik.touched.names && formik.errors.names}
          />
        </Box>
        {formik.values.legal_id_type != "NIT" && (
          <Box className="TextField-without-border-radius">
            <InputLabel shrink className="Input-label">
              Apellidos
            </InputLabel>
            <TextField
              name="last_names"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: "100%", borderRadius: "14px" }}
              value={formik.values.last_names}
              onChange={formik.handleChange}
              error={formik.touched.last_names && Boolean(formik.errors.last_names)}
              helperText={formik.touched.last_names && formik.errors.last_names}
            />
          </Box>
        )}
        <Box className="TextField-without-border-radius">
          <InputLabel shrink className="Input-label">
            Tipo de documento
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            name="legal_id_type"
            select
            sx={{ width: "100%" }}
            value={formik.values.legal_id_type}
            onChange={formik.handleChange}
            error={formik.touched.legal_id_type && Boolean(formik.errors.legal_id_type)}
            helperText={formik.touched.legal_id_type && formik.errors.legal_id_type}
          >
            <MenuItem value={"CC"}>CC</MenuItem>
            <MenuItem value={"CE"}>CE</MenuItem>
            <MenuItem value={"PP"}>PP</MenuItem>
            <MenuItem value={"NIT"}>NIT</MenuItem>
          </TextField>
        </Box>
        <Box className="TextField-without-border-radius">
          <InputLabel shrink className="Input-label">
            Número de documento
          </InputLabel>
          <TextField
            name="legal_id_code"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "100%", borderRadius: "14px" }}
            value={formik.values.legal_id_code}
            onChange={formik.handleChange}
          />
        </Box>
        {formik.values.legal_id_type == "NIT" && (
          <Box className="TextField-without-border-radius">
            <InputLabel shrink className="Input-label">
              Código de verificación
            </InputLabel>
            <TextField
              name="verification_code"
              InputLabelProps={{shrink: true}}
              sx={{ width: "100%", borderRadius: "14px" }}
              value={formik.values.verification_code}
              onChange={formik.handleChange}
              error={formik.touched.verification_code && Boolean(formik.errors.verification_code)}
              helperText={formik.touched.verification_code && formik.errors.verification_code}
            />
          </Box>
        )}
        <Box className="TextField-without-border-radius">
          <InputLabel shrink className="Input-label">
            Teléfono
          </InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                name="phones"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                sx={{ width: "100%", borderRadius: "14px" }}
                value={getPriority(formik.values.phones)?.phone}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={5} display="center">
              <LoadingButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={openModalAddPhone}
                className="Loading-button"
                sx={{ width: "100%" }}
                loading={isLoadingSend}
              >
                <Box style={{ fontWeight: "bold", margin: 0, color: "#53358E" }}>Mostrar teléfonos</Box>
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <Box className="TextField-without-border-radius">
          <InputLabel shrink className="Input-label">
            App user
          </InputLabel>
          <TextField
            name="app_user_id"
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            sx={{ width: "100%", borderRadius: "14px" }}
            value={formik.values.app_user_id}
            onChange={formik.handleChange}
            error={formik.touched.app_user_id && Boolean(formik.errors.app_user_id)}
            helperText={formik.touched.app_user_id && formik.errors.app_user_id}
          />
        </Box>
      </Box>
      <LoadingButton
        variant="contained"
        color="primary"
        startIcon={<EditIcon />}
        size="small"
        type="submit"
        className="Loading-button"
        sx={{ width: "100%", mt: "20px" }}
        loading={isLoadingUser}
      >
        <Box style={{ fontWeight: "bold", margin: 0 }}>Editar información</Box>
      </LoadingButton>
      {(hasErrorUserSend != "" || successUserSend != "") && (
        <CustomAlert
          onClose={() => dispatch(setResetVars())}
          sx={{ marginTop: "20px", backgroundColor: "#fee4e2 !important" }}
          severity={hasErrorUserSend != "" ? "error" : "success"}
          text={hasErrorUserSend != "" ? hasErrorUserSend : successUserSend}
        />
      )}
      {hasErrorUser && (
        <CustomAlert
          onClose={() => dispatch(setResetVars())}
          sx={{ marginTop: "20px", backgroundColor: "#fee4e2 !important" }}
          severity="error"
          text={"Ocurrió un error"}
        />
      )}
      {successUser != "" && (
        <CustomAlert
          onClose={() => dispatch(setResetVars())}
          sx={{ marginTop: "20px", backgroundColor: "#d1fadf !important" }}
          severity="success"
          text={successUser}
        />
      )}
      <ModalArrayForm type="emails" formik={formik} ref={newModalAddMail} />
      <ModalArrayForm type="phones" formik={formik} ref={newModalAddPhones} />
    </form>
  );
};

export default UserInformationForm;
