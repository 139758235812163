import {
  Box,
  Button,
  CustomModalTypes,
  DialogInformation,
  EditOutlinedIcon,
  Grid,
  IconButton,
  enerbitColors,
  theme,
} from "@enerbit/base";

import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../assets/css/serviceAccount.scss";
import {
  searchUser,
  setResetVars,
} from "../../../../features/serviceUser/serviceUserSlice";
import { AppDispatch } from "../../../../store/store";
import UserInformationForm from "./UserInformationForm";
import EditUsername from "./modal/EditUsername";

const UserMainInformation = () => {
  const dataUser = useSelector(searchUser);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const editUsername = useRef<CustomModalTypes>(null);

  const callForgetPassword = () => {
    if (editUsername.current) {
      editUsername.current.changeModal();
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{ fontSize: "28px", fontWeight: "bold" }}
            color={theme.palette.primary.main}
          >
            {dataUser != null
              ? `${dataUser?.pii.names} ${dataUser?.pii.last_names ?? ''}`
              : ""}
          </Box>
          <Box sx={{ fontSize: "16px" }} color={theme.palette.primary.main}>
            Nombre de usuario: {dataUser != null ? `${dataUser?.username}` : ""}{" "}
            <IconButton
              onClick={() => {
                callForgetPassword();
              }}
            >
              <EditOutlinedIcon
                sx={{
                  color: enerbitColors.secondary.orange.main,
                  fontSize: "19px",
                }}
              />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "end" }}>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => setIsDrawerOpen(true)}
          >
            <Box style={{ fontWeight: "bold", fontSize: "16px" }}>
              Ver información
            </Box>
          </Button>
        </Grid>
        <DialogInformation
          sx={{}}
          onCloseAction={() => dispatch(setResetVars())}
          content={<UserInformationForm />}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      </Grid>
      <EditUsername ref={editUsername} />
    </Fragment>
  );
};

export default UserMainInformation;
