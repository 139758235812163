import {
  Box,
  CircularProgress,
  getMagicalParamUrl,
  InputAdornment,
  SearchIcon,
  SupervisedUserCircleOutlinedIcon,
  TextField,
  Typography,
} from "@enerbit/base";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "src/models/stateStorage";
import {
  getCardsServiceAccount,
  getServiceAccountsRelationships,
  isLoading,
  serviceAccountSelect,
  setDefaultServiceAccount,
  setServicesAccountSelect,
  tabSelectedAccessManagement,
} from "../../../../features/serviceAccount/serviceAccountSlice";
import { searchUser } from "../../../../features/serviceUser/serviceUserSlice";
import {
  getAliasServiceGroup,
  getParamsUrl,
} from "../../../../helpers/helperAccounts";
import { reloadServicesAccount } from "../../../../helpers/helperSingleSpa";
import { ServicesAccount } from "../../../../models/serviceAccountState";
import { AppDispatch } from "../../../../store/store";

const ServiceGroup = () => {
  const service = useSelector(serviceAccountSelect);
  const userId = getMagicalParamUrl()

  const { servicesAccountData, isLoadingSingleServiceAccount } = useSelector(
    (state: StateStorage) => state.serviceAccount
  );

  const tabAccessManagement = useSelector(tabSelectedAccessManagement);
  const loading = useSelector(isLoading);
  const dataUser = useSelector(searchUser);
  const dispatch = useDispatch<AppDispatch>();
  const scrollDivRef = useRef<HTMLDivElement>(null);

  const getCardServices = (serviceAccounId: string) => {
    if (dataUser && dataUser?.id == userId && service != "") {
      dispatch(getCardsServiceAccount(serviceAccounId));
    }
  };

  const componentServiceGroup = (value: ServicesAccount, index: number) => {
    return (
      <Box
        key={index}
        onClick={() =>
          dispatch(setServicesAccountSelect(value.service_account_id))
        }
        className={
          value.service_account_id === service
            ? "Button-services Button-services-selected"
            : "Button-services"
        }
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box sx={{ width: "10vh" }}>
            <Box className="User-circle-icon">
              <SupervisedUserCircleOutlinedIcon
                sx={{ color: "var(--color-primary)" }}
              />
            </Box>
          </Box>
          <Box sx={{ width: "80vh", mt: "4px" }}>
            <Typography
              sx={{ ml: "10px" }}
              className={
                value.service_account_id === service
                  ? "Button-services-selected"
                  : ""
              }
            >
              Service Group {getAliasServiceGroup(value)}
            </Typography>
          </Box>
          <Box sx={{ width: "10vh" }}>{/* <MoreVertIcon /> */}</Box>
        </Box>
      </Box>
    );
  };

  // TODO: Refactorizar código inestable
  // const filterServiceAccount = (value: string) => {
  //   let filtered: ServicesAccount[] | string = [];

  //   if (value != "") {
  //     filtered = servicesAccountData.items.filter((service) =>
  //       service.service_account_id.substring(0, 8).includes(value)
  //     );
  //     if (filtered.length === 0) {
  //       setNoRecordsFilter("No se encontraron registros");
  //     }
  //   } else {
  //     setNoRecordsFilter("");
  //   }

  //   dispatch(setServicesAccountDataFilter(filtered));
  // };

  useEffect(() => {
    dispatch(setDefaultServiceAccount());
    if (service != "" && dataUser) {
      const search = servicesAccountData.items.find(
        (item) => item.service_account_id == service
      );
      if (search) {
        reloadServicesAccount(service, tabAccessManagement, true, {
          user_id: userId ?? "",
        });
        getCardServices(service);
      }
    }
  }, [service, dataUser]);

  useEffect(() => {
    if (servicesAccountData.items.length > 0 && (!service || service == "")) {
      const serviceGroup = getParamsUrl("serviceGroup");
      if (serviceGroup != "") {
        dispatch(setServicesAccountSelect(serviceGroup));
      } else {
        dispatch(
          setServicesAccountSelect(
            servicesAccountData.items[0].service_account_id
          )
        );
      }
    }

    if(servicesAccountData.items.length == 0){
      reloadServicesAccount("", tabAccessManagement, true, {
        user_id: userId ?? "",
      });
    }
  }, [servicesAccountData]);

  const handleScroll = () => {
    const scrollDiv = scrollDivRef.current;

    if (scrollDiv) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDiv;

      if (
        scrollTop + clientHeight >= scrollHeight - 100 &&
        !loading &&
        !isLoadingSingleServiceAccount &&
        servicesAccountData.next_page != null
      ) {
        dispatch(
          getServiceAccountsRelationships({
            page: servicesAccountData.next_page,
            size: 50,
          })
        );
      }
    }
  };
  useEffect(() => {
    const scrollDiv = scrollDivRef.current;

    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);
  return (
    <>
      <Box ref={scrollDivRef} sx={{ overflowX: "auto", maxHeight: "86vh" }}>
        {loading && servicesAccountData.items.length == 0 ? (
          <Box className="Loading">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="Button-groups">
            <Box className="Know-services" sx={{ mb: "20px" }}>
              Grupo de servicios
            </Box>
            <Box
              className="TextField-without-border-radius Text-select-field"
              sx={{ mb: "15px" }}
            >
              <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  // TODO: Refactorizar código inestable
                  // filterServiceAccount(event.target.value);
                }}
                placeholder="Buscar"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "100%", borderRadius: "14px" }}
              />
            </Box>
            {servicesAccountData.items.map((value, index) =>
              componentServiceGroup(value, index)
            )}
            {(loading || isLoadingSingleServiceAccount) &&
              servicesAccountData.items.length != 0 && (
                <Box
                  width="100%"
                  sx={{
                    marginTop: "16px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default ServiceGroup;
