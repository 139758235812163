import {
  api,
  cleanDataForm,
  getArraysObjectEdit,
  getMagicalParamUrl,
} from "@enerbit/base";
import {
  DataSendClient,
  DataUpdateSearchUser,
  ErrorsArray,
  OriginalArray,
} from "@enerbit/base/common/models/validateArray";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  SearchUserMain,
  ServiceUserState,
} from "../../models/serviceUserState";
import { StateStorage } from "../../models/stateStorage";

export const getSearchUser = createAsyncThunk(
  "serviceUser/getSearchUser",
  async (_, thunkAPI) => {
    let param = getMagicalParamUrl();

    if (param != null) {
      const res = await api.get(`/users/app-users/${param}`);
      return res.data;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "serviceUser/setResetPassword",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    if (state.serviceUser.searchUser) {
      const res = await api.post(
        `/users/app-users/${state.serviceUser.searchUser.id}/reset-password`
      );
      return res.data;
    }
  }
);
const getEditPhonesEmails = async (
  originalArray: OriginalArray,
  newArray: DataUpdateSearchUser | DataSendClient,
  pii: string
) => {
  const editEmails = getArraysObjectEdit(originalArray, newArray, "emails");
  for await (const emails of editEmails.deletes) {
    await api.delete(`/users/app-users/pii-users/${pii}/emails/${emails.id}`);
  }
  for await (const emails of editEmails.update) {
    await api.patch(
      `/users/app-users/pii-users/${pii}/emails/${emails.id}`,
      emails
    );
  }
  for await (const emails of editEmails.create) {
    await api.post(`/users/app-users/pii-users/${pii}/emails`, emails);
  }
  const editPhones = getArraysObjectEdit(originalArray, newArray, "phones");
  for await (const phones of editPhones.deletes) {
    await api.delete(`/users/app-users/pii-users/${pii}/phones/${phones.id}`);
  }
  for await (const phones of editPhones.update) {
    await api.patch(
      `/users/app-users/pii-users/${pii}/phones/${phones.id}`,
      phones
    );
  }
  for await (const phones of editPhones.create) {
    await api.post(`/users/app-users/pii-users/${pii}/phones`, phones);
  }
};

const getUpdateSearchUserRawData = (body: DataUpdateSearchUser) => {
  return {
    loggable: body.loggable,
    is_first_time: body.is_first_time,
    app_user_id: body.app_user_id,
    names: body.names,
    last_names: body.last_names || null,
    legal_id_type: body.legal_id_type,
    legal_id_code: body.legal_id_code,
    verification_code: body.verification_code || null,
    emails: body.emails,
    phones: body.phones,
  };
};

export const updateSearchUser = createAsyncThunk(
  "serviceUser/updateSearchUser",
  async (body: DataUpdateSearchUser, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    if (state.serviceUser.searchUser) {
      const cleanData = cleanDataForm(body);
      const rawData = getUpdateSearchUserRawData(body);
      await getEditPhonesEmails(
        state.serviceUser.editClientArray,
        cleanData,
        state.serviceUser.searchUser.pii.id
      );
      const res = await api.patch(
        `/users/app-users/${state.serviceUser.searchUser.pii.id}/`,
        rawData
      );
      await thunkAPI.dispatch(getSearchUser());
      return res.data;
    }
  }
);

export const updateUsername = createAsyncThunk(
  "serviceUser/updateUsername",
  async (username: string, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    if (state.serviceUser.searchUser) {
      const res = await api.patch(
        `/users/app-users/${state.serviceUser.searchUser.id}/username?username=${username}`
      );
      return res.data.username;
    }
  }
);

const initialState: ServiceUserState = {
  hasErrorUser: false,
  hasErrorUserEdit: false,
  successUserEdit: "",
  isLoadingUser: false,
  isLoadingUserEdit: false,
  searchUser: undefined,
  isLoadingReset: false,
  successSendMessage: "",
  errorSendMessage: "",
  editClientArray: {
    emails: [],
    phones: [],
  },
  errorsArray: {
    emails: {},
    phones: {},
  },
  isLoadingUpdateUsername: false,
  wasUpdateUsername: null,
  showConfirmDialog: false,
};

export const serviceUserSlice = createSlice({
  name: "serviceUser",
  initialState,
  reducers: {
    clearSearchUser: (state) => {
      state.searchUser = undefined;
    },
    setResetVars: (state) => {
      state.hasErrorUserEdit = false;
      state.successUserEdit = "";
      state.successSendMessage = "";
      state.errorSendMessage = "";
      state.showConfirmDialog = false;
      state.isLoadingReset = false;
      state.isLoadingUpdateUsername = false;
      state.wasUpdateUsername = null;
    },
    setEditClientArray: (state, action: PayloadAction<OriginalArray>) => {
      state.editClientArray = action.payload;
    },
    setShowConfirmDialog: (state, action: PayloadAction<boolean>) => {
      state.showConfirmDialog = action.payload;
    },
    validateArrayFieldsState: (state, action: PayloadAction<ErrorsArray>) => {
      state.errorsArray = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSearchUser.pending.type, (state) => {
        state.isLoadingUserEdit = true;
      })
      .addCase(updateSearchUser.fulfilled.type, (state) => {
        state.successUserEdit = "Usuario actualizado exitosamente";
        state.isLoadingUserEdit = false;
        state.hasErrorUserEdit = false;
      })
      .addCase(updateSearchUser.rejected.type, (state) => {
        state.isLoadingUserEdit = false;
        state.hasErrorUserEdit = true;
      })
      .addCase(getSearchUser.pending.type, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(
        getSearchUser.fulfilled.type,
        (state, action: PayloadAction<SearchUserMain>) => {
          state.searchUser = action.payload;
          state.isLoadingUser = false;
        }
      )
      .addCase(getSearchUser.rejected.type, (state) => {
        state.isLoadingUser = false;
        state.hasErrorUser = true;
      })
      .addCase(resetPassword.pending.type, (state) => {
        state.isLoadingReset = true;
      })
      .addCase(resetPassword.fulfilled.type, (state) => {
        state.successSendMessage = "Solicitud enviada con exito";
        state.isLoadingReset = false;
      })
      .addCase(resetPassword.rejected.type, (state) => {
        state.errorSendMessage = "Ocurrio un error al enviar la solicitud";
        state.isLoadingReset = false;
      })
      .addCase(updateUsername.pending.type, (state) => {
        state.isLoadingUpdateUsername = true;
        state.wasUpdateUsername = null;
      })
      .addCase(
        updateUsername.fulfilled.type,
        (state, payload: PayloadAction<string>) => {
          state.wasUpdateUsername = true;
          if (state.searchUser) {
            const changeUsernameState = { ...state.searchUser };
            changeUsernameState.username = payload.payload;
            state.searchUser = changeUsernameState;
            const changeUsernameStorage = sessionStorage.getItem("authInfo");
            if (changeUsernameStorage) {
              const parse = JSON.parse(changeUsernameStorage);
              parse.email = payload.payload;
              sessionStorage.setItem("authInfo", JSON.stringify(parse));
            }
          }

          state.isLoadingUpdateUsername = false;
        }
      )
      .addCase(updateUsername.rejected.type, (state) => {
        state.wasUpdateUsername = false;
        state.isLoadingUpdateUsername = false;
      });
  },
});

export const {
  clearSearchUser,
  setResetVars,
  setEditClientArray,
  validateArrayFieldsState,
  setShowConfirmDialog,
} = serviceUserSlice.actions;

export const hasError = (state: StateStorage) => state.serviceUser.hasErrorUser;
export const editClientArray = (state: StateStorage) =>
  state.serviceUser.editClientArray;
export const errorsArray = (state: StateStorage) =>
  state.serviceUser.errorsArray;
export const isLoading = (state: StateStorage) =>
  state.serviceUser.isLoadingUser;
export const isLoadingUserEdit = (state: StateStorage) =>
  state.serviceUser.isLoadingUserEdit;
export const hasErrorUserEdit = (state: StateStorage) =>
  state.serviceUser.hasErrorUserEdit;
export const successUserEdit = (state: StateStorage) =>
  state.serviceUser.successUserEdit;
export const searchUser = (state: StateStorage) => state.serviceUser.searchUser;
export const successSendMessage = (state: StateStorage) =>
  state.serviceUser.successSendMessage;
export const errorSendMessage = (state: StateStorage) =>
  state.serviceUser.errorSendMessage;
export const isLoadingReset = (state: StateStorage) =>
  state.serviceUser.isLoadingReset;
export const wasUpdateUsername = (state: StateStorage) =>
  state.serviceUser.wasUpdateUsername;
export const isLoadingUpdateUsername = (state: StateStorage) =>
  state.serviceUser.isLoadingUpdateUsername;
export const showConfirmDialog = (state: StateStorage) =>
  state.serviceUser.showConfirmDialog;

export default serviceUserSlice.reducer;
